import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import {useParams, useNavigate } from 'react-router-dom';
import { updateUserId, updateUserFirstName, updateUserAuth, updateUserSubscription } from '../../store/models/userSlice';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Grid, Typography, Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        ml: 0,
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx
      }}
      {...other}
    />
  );
}

const auth = () => {

    const params = useParams();
    const auth = useRef(true);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const  authenticate = async () => {
        try {
            axios.defaults.withCredentials = true;
            await axios.get('https://affiliatebased.ratefy.co/sanctum/csrf-cookie');
            const response = await axios.post('https://affiliatebased.ratefy.co/api/verify-user', {
              uuid: params.uuid,
              auth: params.auth
            });
            if (response.data[0].status === 'approved') {
              return true;             
            } else {
              return false;  
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }


    const checkSubscriber = async () => {
      try {
        axios.defaults.withCredentials = true;
        await axios.get('https://affiliatebased.ratefy.co/sanctum/csrf-cookie');
        const result = await axios.post(
          'https://affiliatebased.ratefy.co/api/fetch-subcriber',
          {
            uuid: params.uuid
          },
          {
            header: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            }
          }
        );
  
        if (result.data.user === '404') {
          return false;
        } else  {
          return true;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const saveUserData = () => {
      dispatch(updateUserId(params.uuid));
      dispatch(updateUserFirstName(params.firstname));
      dispatch(updateUserAuth(params.auth));
    }

    const Scrutiny = async () => {
      
      let authenticated =  await authenticate();
            if (authenticated === true) {
              let subscribed =  await checkSubscriber();
              
              if(subscribed === true) {
                saveUserData();
                dispatch(updateUserSubscription());
                 navigate("/v/partners");
              }else {
                saveUserData();
                return navigate("/");
              }
            }else {
              console.log("relocate user");
              // window.location.href = 'https://ratefy.co';
            }
    }
    useEffect( ()  => { 
        if(auth.current === true){
            auth.current = false;
            Scrutiny();
        }
    });

  return (
    <>
      <Box
        sx={{
          color: '#ffffff'
        }}
      >
        Authenticating ...
      </Box>
    </>
  );
};

export default auth;
