// material-ui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'center' : 'center',
  paddingLeft: theme.spacing(open ? 3 : 0),
  backgroundColor: "#0C0E0F",
  height: "50px",
}));

export default DrawerHeaderStyled;
