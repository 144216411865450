import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    users: [{
        uuid: null,
        firstname: null,
        auth: null,
        subscribed: false
    }],
}

export const  userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUserId: (state, action) => {
            state.users = state.users.filter(user => user.uuid = action.payload)
        },

        updateUserFirstName: (state, action) => {
            state.users = state.users.filter(user => user.firstname = action.payload)
        },

        updateUserAuth: (state, action) => {
            state.users = state.users.filter(user => user.auth = action.payload)
        },

        updateUserSubscription: (state, action) => {
            state.users = state.users.filter(user => user.subscribed = true)
        },

        destroyUser: (state, action) => {
            state.users = state.users.filter(user => user.uuid          =   null)
            state.users = state.users.filter(user => user.firstname     =   null)
            state.users = state.users.filter(user => user.auth          =   null)
            state.users = state.users.filter(user => user.subscribed    =   false)
        }
    },
}) 

export const {updateUserId, updateUserFirstName, updateUserAuth,  destroyUser, updateUserSubscription } = userSlice.actions;
export default userSlice.reducer;