import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
import DashboardDefault from 'pages/dashboard/index';
import AuthPage from 'pages/dashboard/auth';
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
import SamplePage from 'pages/extra-pages/SamplePage';
import ErrorPage from 'pages/dashboard/error-page';

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  // path: '/',
  // element: <SamplePage />,
  children: [
    {
      path: 'v',
      children: [
        {
          path: 'partners',
          element: <DashboardDefault />
        }
      ]
    },
    {
      //   // path: 'partner',
      path: '/',
      element: <SamplePage />
    },
    {
      path: '/:uuid/:firstname/:auth',
      element: <AuthPage />
    }
  ]
};

export default MainRoutes;
