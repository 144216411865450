import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const iconBackColor = 'grey.100';
  const iconBackColorOpen = 'grey.200';
  const mainHeader = (
    <Toolbar
      sx={{
        height: '60px',
        width: '100%',
        '@media (min-width:1536px)': {
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    >
      {/* <IconButton
        size="small"
        disableRipple
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        sx={{
          color: 'text.primary',
          bgcolor: open ? iconBackColorOpen : iconBackColor,
       //   ml: { xs: 0, lg: -2 },
          display: { xs: 'block', lg: 'none' }
        }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton> */}
      <HeaderContent />
    </Toolbar>
  );
  const appBar = {
    position: 'fixed',
    elevation: 0,
    sx: {
      backgroundColor: '#0C0E0F',
      color: '#FFFFFF',
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
