import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import Drawer from './Drawer';
import Header from './Header';
import { openDrawer } from 'store/reducers/menu';
const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor: '#181C1F'
      }}
    >
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {/* <Drawer open={open} handleDrawerToggle={handleDrawerToggle} /> */}
      <Box
        component="main"
        sx={{
          width: '100%',
          flexGrow: 1,
          p: 0,
          height: '100vh',
          '@media (min-width:1536px)': {
            display: 'flex',
            justifyContent: 'center',
            pt: 5
          }
        }}
      >
        <Toolbar />
        <Outlet
          sx={{
            height: '100vh'
          }}
        />
      </Box>
    </Box>
  );
};

export default MainLayout;
