
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer  }  from 'redux-persist';
import thunk from 'redux-thunk';
import reducers from './reducers';


const config = {
  key: 'root',
  storage
}


const reducer = persistReducer(config, reducers)

const store = configureStore({
    reducer: reducer, 
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

const { dispatch } = store;

export { store, dispatch };
