import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Link } from 'react-router-dom';
import Select from '@mui/material/Select';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { relocate } from '../../helpers/relocate.js';
import { authorization } from '../../helpers/authorization.js';
import { destroyUser } from '../../helpers/destroyauth.js';
import { useDispatch } from 'react-redux';

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        ml: 0,
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object
  ])
};

const rowsPerPageOptions = [5, 10, 25];
const initialRowsPerPage = rowsPerPageOptions[0];

const DashboardDefault = () => {
  const effectA = useRef(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const Scrutiny = async () => {
    let authorize = authorization(user.users[0].uuid);

    if (authorize === false) {
      relocate();
    } else {
      fetchAccountDetails();
      setActiveButton('Invitation');
      fetchData();
    }
  };

  const [accountDetails, setAccountDetails] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
  };

  const fetchAccountDetails = async () => {
    try {
      const response = await axios.post(
        'https://affiliatebased.ratefy.co/api/get-user-account',

        {
          uuid: user.users[0].uuid
        }
      );
      if (response.data && response.data.accountdetails && response.data.accountdetails.accountdetails) {
        const accountData = response.data.accountdetails.accountdetails;
        setAccountDetails(accountData);
        console.log(accountData);
      } else {
        console.error('API response does not contain the expected data structure:', response);
      }
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };

  useEffect(() => {
    if (effectA.current === true) {
      effectA.current === false;
      Scrutiny();
    }
  }, []);
  const [showEarningHistory, setShowEarningHistory] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [showInvitation, setInvitation] = useState(true);
  const [activeButton, setActiveButton] = useState(null);

  const [userData, setUserData] = useState(null);
  const fetchData = async () => {
    try {
      axios.defaults.withCredentials = true;
      // await axios.get('https://affiliatebased.ratefy.co/sanctum/csrf-cookie');
      const response = await axios.post(
        'https://affiliatebased.ratefy.co/api/fetch-subcriber',
        {
          uuid: user.users[0].uuid
        },
        {
          header: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
      );

      setUserData(response.data.user);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleEarningHistory = () => {
    setShowEarningHistory(true);
    setShowLearnMore(false);
    setInvitation(false);
    setActiveButton('Earning History');
  };

  const toggleLearnMore = () => {
    setShowEarningHistory(false);
    setShowLearnMore(true);
    setInvitation(false);
    setActiveButton('Learn More');
  };
  const invitation = () => {
    setShowEarningHistory(false);
    setShowLearnMore(false);
    setInvitation(true);
    setActiveButton('Invitation');
  };

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: `Edward King ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}`
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const start = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  const hasSelected = selectedRowKeys.length > 0;

  const [availableBalance, setAvailableBalance] = useState(null);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  const rowsPerPageOptions = [5, 10, 25];

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9)
  ];

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage]
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const copyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };
  const [clipboardText, setClipboardText] = React.useState('');
  const handleCopyToClipboard = (text) => {
    copyToClipboard(text);
    setClipboardText(text);
    toast('Text copied to Clipboard', { type: 'success' });
  };
  const [emails, setEmails] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValid(validateEmail(newEmail));
  };
  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  const handleSendClick = () => {
    if (isValid) {
      console.log('Message sent to emails successfully');
    }
  };

  const handleEmailAdd = () => {
    if (isValid) {
      setEmails([...emails, email]);
      setEmail('');
    }
  };

  const handleEmailRemove = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const [email, setEmail] = useState('');
  const [emailChips, setEmailChips] = useState([]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddEmail = async () => {
    try {
      axios.defaults.withCredentials = true;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email)) {
        setEmailChips((prev) => [...prev, email]);
        setEmail('');
      }

      const toConvertMail = email.toString();
      const listEmail = toConvertMail.split(', ');

      const emailObj = { ...listEmail };
      console.log(emailObj);

      const payload = { campeignemails: emailObj, affiliate_id: user.users[0].uuid };
      const response = await axios.post('https://affiliatebased.ratefy.co/api/run-campeign', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      console.log(response.data);
      if (response.status === 200) {
        setEmailChips((prev) => [...prev, listEmail]);
        console.log('Emails sent successfully', response.data);
      }
    } catch (error) {
      console.log(email);
      console.error('An error occurred while sending emails', error);
    }
  };

  const sendReminder = async () => {
    try {
      axios.defaults.withCredentials = true;
      const payload = { uuid: user.users[0].uuid };
      const response = await axios.post('https://affiliatebased.ratefy.co/api/run-first-reminder-campeign', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      console.log(response.data);
      if (response.status === 200) {
        console.log('reminder sent successfully', response.data);
      }
    } catch (error) {
      console.log(email);
      console.error('An error occurred while sending reminder', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddEmail();
      e.preventDefault();
    }
  };

  const handleDeleteEmail = (chipToDelete) => () => {
    setEmailChips((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const [open, setOpen] = useState(false);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  // share
  const fshare = async () => {
    try {
      axios.defaults.withCredentials = true;
      const payload = { uuid: user.users[0].uuid };
      const response = await axios.post('https://affiliatebased.ratefy.co/api/social-media-share', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      console.log(response.data);
      if (response.status === 200) {
        console.log('fshare successful', response.data.share.facebook);
        window.open(response.data.share.facebook, '_blank');
      }
    } catch (error) {
      console.error('An error occurred while sharing facebook', error);
    }
  };

  const tshare = async () => {
    try {
      axios.defaults.withCredentials = true;
      const payload = { uuid: user.users[0].uuid };
      const response = await axios.post('https://affiliatebased.ratefy.co/api/social-media-share', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      console.log(response.data);
      if (response.status === 200) {
        console.log('fshare successful', response.data.share.facebook);
        window.open(response.data.share.twitter, '_blank');
      }
    } catch (error) {
      console.error('An error occurred while sharing twitter', error);
    }
  };

  const wshare = async () => {
    try {
      axios.defaults.withCredentials = true;
      const payload = { uuid: user.users[0].uuid };
      const response = await axios.post('https://affiliatebased.ratefy.co/api/social-media-share', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      console.log(response.data);
      if (response.status === 200) {
        console.log('fshare successful', response.data.share.facebook);
        window.open(response.data.share.whatsapp, '_blank');
      }
    } catch (error) {
      console.error('An error occurred while sharing whatsapp', error);
    }
  };
  const ishare = async () => {
    try {
      axios.defaults.withCredentials = true;
      const payload = { uuid: user.users[0].uuid };
      const response = await axios.post('https://affiliatebased.ratefy.co/api/social-media-share', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      console.log(response.data);
      if (response.status === 200) {
        console.log('ishare successful', response.data.share.linkedin);
        window.open(response.data.share.linkedin, '_blank');
      }
    } catch (error) {
      console.error('An error occurred while sharing linkedin', error);
    }
  };

  // end of share

  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const handleRequest = async () => {
    setOpen(false);
    setOpenSecondDialog(true);

    try {
      const balance = userData.availablebalance.balance;
      const bankId = selectedAccount;
      const userId = userData.uuid;
      const url = 'https://affiliatebased.ratefy.co/api/withdrawal';
      const data = {
        amount: balance,
        uuid: userId,
        bank_uuid: bankId
      };
      const response = await axios.post('https://affiliatebased.ratefy.co/api/withdrawal', {
        amount: balance,
        uuid: userId,
        bank_uuid: bankId
      });
      // setOpen(false);
      // setOpenSecondDialog(true);
      // alert(JSON.stringify(response.data));
      console.log(response.data.message);
      const statusCode = response.status;

      if (statusCode === 200) {
        setMessage(response.data.message); // Update the state variable
        setTitle(response.data.title); // Update the state variable

        // sentFriday = response.data.message;
        // alert('Withdrawal request sent successfully');
        console.log('Withdrawal request sent successfully');
      } else {
        // alert(`Received unexpected status code ${statusCode}`);
      }

      console.log('API Response:', response.data);
    } catch (error) {
      alert(`Error: ${error}`);
    }
  };

  const gotodashboard = () => {
    window.location.href = 'https://ratefy.co/users/home';
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSecondDialog = () => {
    setOpenSecondDialog(false);
  };
  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, []);

  const modalBody = (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        backgroundColor="none"
        background="none"
        PaperProps={{
          style: { backgroundColor: 'transparent' }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '24px', color: '#fff' }}>Withdrawal Request</DialogTitle>
        <Grid container spacing={1}>
          <Grid item md={10} sm={10} xs={12}>
            <DialogContent
              sx={{
                pl: 5,
                color: '#A6A6A6',
                backgroundColor: '#1C2225'
              }}
            >
              <Box
                sx={{
                  mb: 3,
                  pt: 1
                }}
              >
                <Box>Request Withdrawal from your Ratefy Partnership wallet to any naira bank account of your own.</Box>

                <Box
                  sx={{
                    mt: 5,
                    width: '100%'
                  }}
                >
                  <InputLabel sx={{ color: '#CCCCCC', mb: 1 }}>Selete Bank account</InputLabel>

                  <FormControl
                    sx={{
                      m: 0,
                      color: '#FFFFFF',
                      width: '100%',
                      '& .MuiInputBase-root': {
                        borderRadius: '10px'
                      }
                    }}
                  >
                    <Select
                      value={selectedAccount}
                      onChange={(e) => setSelectedAccount(e.target.value)}
                      input={
                        <OutlinedInput
                          value={selectedAccount}
                          sx={{
                            '& .MuiInputBase-root': {
                              borderRadius: '10px'
                            },
                            color: '#FFFFFF',
                            mr: 0,
                            pr: 0
                          }}
                        />
                      }
                    >
                      <MenuItem value="default" disabled>
                        Select an account
                      </MenuItem>
                      {accountDetails.map((account, index) => (
                        <MenuItem key={index} value={`${account.uuid}`}>
                          {account.bank_name} || {account.account_number} || {account.account_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: '#1C2225'
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  autoFocus
                  onClick={handleRequest}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#00B172',
                    pl: 10,
                    pr: 10,
                    '&:hover': {
                      backgroundColor: '#035e3e'
                    }
                  }}
                >
                  Request Withdrawal
                </Button>
              </DialogActions>

              <Grid item></Grid>
            </DialogContent>
          </Grid>
          <Grid item md={2} sm={2}>
            <Button
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                backgroundColor: '#00B172',
                color: '#000000',
                '&:hover': {
                  backgroundColor: '#035e3e'
                }
              }}
            >
              <iconify-icon
                icon="icon-park-solid:big-x"
                style={{
                  margin: 0,
                  padding: 0
                }}
              ></iconify-icon>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );

  const secondDialog = (
    <>
      <Dialog
        fullWidth
        open={openSecondDialog}
        onClose={() => setOpenSecondDialog(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: { backgroundColor: 'transparent' }
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={10} sm={10} xs={12}>
            <DialogContent
              fullWidth
              sx={{
                pl: 5,
                color: '#A6A6A6',
                backgroundColor: '#1C2225',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <img
                  width="100px"
                  src="https://res.cloudinary.com/pro-solve/image/upload/v1697640509/clarity_success-standard-solid_tfpa5e.png"
                  alt="man"
                />
              </Box> */}

              <Box
                component={Link}
                to="https://ratefy.co/users/home"
                textAlign="center"
                sx={{
                  fontSize: '26px',
                  dislay: 'flex',
                  justifyContent: 'center',
                  color: '#fff',
                  pt: 3,
                  pb: 1
                }}
              >
                {title ? title : 'Loading'}
              </Box>
              <Box textAlign="center">{message ? message : 'Loading...'}</Box>
              {/* <Box textAlign="center">Your money will be sent to your bank</Box> */}
              {/* <Box
                textAlign="center"
                sx={{
                  mb: 4
                }}
              >
                account in less than 24 hours
              </Box> */}

              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: '#1C2225'
                }}
              ></DialogActions>

              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 4
                }}
              >
                Transaction details
              </Box> */}
            </DialogContent>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            sx={{
              backgroundColor: 'none'
            }}
          >
            <Button
              aria-label="close"
              onClick={handleCloseSecondDialog}
              sx={{
                position: 'absolute',
                backgroundColor: '#00B172',
                color: '#000000',
                '&:hover': {
                  backgroundColor: '#035e3e'
                }
              }}
            >
              <iconify-icon
                icon="icon-park-solid:big-x"
                style={{
                  margin: 0,
                  padding: 0
                }}
              ></iconify-icon>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );

  return (
    <Box
      sx={{ ml: { md: 3, sm: 0 },
        p: '24px',
        '@media (min-width:1440px)': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly'
        }
      }}
    >
      {userData && (
        <>
          <Box
            sx={{
              color: '#FFFFFF',
              fontSize: '30px',
              fontWeight: '700'
            }}
          >
            Ratefy {''}
            <span
              style={{
                background: `linear-gradient(to right, #3AFF4E, #3AFF4E)`,
                WebkitBackgroundClip: 'text',
                color: 'transparent'
              }}
            >
              Partnership
            </span>
          </Box>
          <Box
            sx={{
              color: '#BBBBBB'
            }}
          >
            Earn up to ₦500k on each user through revenue share.
          </Box>
          <Box
            sx={{
              backgroundColor: '#21272A',
              mt: 5,
              color: '#FFFFFF',
              pl: 3,
              pr: 3,
              pt: 3,
              pb: 3
            }}
          >
            <Box
              sx={{
                color: '#BBBBBB'
              }}
            >
              Available earnings
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: {
                  md: 'space-between',
                  sm: 'space-between',
                  xs: 'flex-start'
                },
                flexDirection: {
                  md: 'row',
                  sm: 'row',
                  xs: 'column'
                }
              }}
            >
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{
                  fontSize: '30px'
                }}
              >
                &#x20A6;{userData.availablebalance.balance}
              </Typography>
              <Box
                sx={{
                  pt: {
                    xs: 2,
                    sm: 0,
                    md: 0
                  },
                  pb: {
                    xs: 5,
                    sm: 0,
                    md: 0
                  }
                }}
              >
                <Button
                  onClick={handleClickOpen()}
                  sx={{
                    backgroundColor: '#EAEAEA',
                    color: '#000000',
                    '&:hover': {
                      backgroundColor: '#919191',
                      color: 'white'
                    }
                  }}
                >
                  <Typography variant="subtitle1" component="div">
                    <iconify-icon
                      icon="ion:paper-plane"
                      style={{
                        fontSize: '20px',
                        mt: 3,
                        pt: 3
                      }}
                    ></iconify-icon>
                    Withdraw to Bank
                  </Typography>
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                  sx={{
                    backgroundColor: 'none'
                  }}
                >
                  {modalBody}
                </Dialog>
              </Box>
              {secondDialog}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: {
                    md: 'row',
                    sm: 'row',
                    xs: 'row'
                  },
                  justifyContent: {
                    xs: 'space-between',
                    md: 'flex-start'
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    pr: {
                      md: 3,
                      sm: 3
                    }
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={{
                      fontSize: {
                        md: '12px',
                        xs: '10px'
                      },
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    Net earnings
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={{
                      fontSize: {
                        md: '16px',
                        xs: '12px'
                      },
                      color: '#FFFFFF',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    &#x20A6;
                    {userData.currentreferredtransaction.reduce((acc, transaction) => acc + transaction.commission_amount, 0).toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pr: {
                      md: 3,
                      sm: 3
                    }
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={{
                      fontSize: {
                        md: '12px',
                        xs: '10px'
                      },
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    Withdrawn
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={{
                      fontSize: {
                        md: '16px',
                        xs: '12px'
                      },
                      color: '#FFFFFF',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    &#x20A6;
                    {userData.withdrawaldisbursement
                      .filter((item) => item.status === 'paid')
                      .reduce((total, item) => total + item.amount, 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    textAlign="right"
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={{
                      fontSize: {
                        md: '12px',
                        xs: '10px'
                      },
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    Pending Disbursement
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: {
                        md: '16px',
                        xs: '12px'
                      },
                      color: '#FFFFFF'
                    }}
                  >
                    &#x20A6;{' '}
                    {userData.withdrawaldisbursement
                      .filter((item) => item.status === 'processing')
                      .reduce((total, item) => total + item.amount, 0)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              mt: 0.5
            }}
          >
            <Item
              sx={{
                backgroundColor: '#21272A',
                color: '#FFFFFF',
                fontSize: {
                  xs: '10px',
                  md: '14px'
                }
              }}
            >
              <span
                style={{
                  fontSize: '16px',
                  display: {
                    sm: 'block',
                    xs: 'bloxk',
                    md: 'inline'
                  }
                }}
              >
                {userData.count}
              </span>
              &nbsp;
              <span
                style={{
                  display: {
                    sm: 'block',
                    xs: 'block'
                  }
                }}
              >
                Overall Clicks
              </span>
            </Item>
            <Item
              sx={{
                backgroundColor: '#21272A',
                color: '#FFFFFF',
                fontSize: {
                  xs: '10px',
                  md: '14px'
                }
              }}
            >
              <span
                style={{
                  fontSize: '16px'
                }}
              >
                {userData.referred.length}
              </span>{' '}
              All time Registerations
            </Item>
            <Item
              sx={{
                backgroundColor: '#21272A',
                color: '#FFFFFF',
                fontSize: {
                  xs: '10px',
                  md: '14px'
                }
              }}
            >
              <span
                style={{
                  fontSize: '16px'
                }}
              >
                {userData.currentreferredtransaction.length} {''}
              </span>
              Overall Revshare
            </Item>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: {
                xs: 'space-between',
                md: 'flex-start'
              },
              flexWrap: 'nowrap',
              pb: 3,
              pt: 5
            }}
          >
            <Button
              variant="text"
              onClick={invitation}
              sx={{
                color: activeButton === 'Invitation' ? '#00B172' : '#FFFFFF',
                p: 0,
                pb: 0,
                pr: {
                  md: 7
                }
              }}
            >
              Invitation
            </Button>
            <Button
              variant="text"
              onClick={toggleEarningHistory}
              sx={{
                mt: 0,
                fontSize: {
                  md: '15px',
                  xs: '13px'
                },
                color: activeButton === 'Earning History' ? '#00B172' : '#FFFFFF',
                p: 0,
                pr: {
                  md: 7
                }
              }}
            >
              Earning history
            </Button>
            <Button
              variant="text"
              onClick={toggleLearnMore}
              sx={{
                m: 0,
                fontSize: '15px',
                color: activeButton === 'Learn More' ? '#00B172' : '#FFFFFF',
                p: 0,
                pr: {
                  md: 7
                }
              }}
            >
              Reminder
            </Button>
          </Box>

          <Box sx={{ backgroundColor: '#21272A', color: '#FFFFFF' }}>
            {showEarningHistory && (
              <>
                <TableContainer component={Paper} sx={{ backgroundColor: '#181C1F' }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: '#333333',
                          borderBottom: 'none'
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderBottom: '#333333'
                          }}
                        >
                          Activity
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            borderBottom: '#333333'
                          }}
                        >
                          Transaction ID
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            borderBottom: '#333333'
                          }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            borderBottom: '#333333'
                          }}
                        >
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.currentreferredtransaction
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((transaction) => (
                          <TableRow key={transaction.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                              sx={{
                                borderBottom: '1px solid #444444'
                              }}
                            >
                              Commission
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderBottom: '1px solid #444444'
                              }}
                            >
                              {transaction.uuid}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderBottom: '1px solid #444444'
                              }}
                            >
                              Revshare
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderBottom: '1px solid #444444'
                              }}
                            >
                              &#x20A6; {transaction.commission_amount.toLocaleString('en-US')}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      color: '#FFFFFF'
                    }}
                  />
                </Box>
              </>
            )}
            <Box
              sx={{
                ml: {
                  md: 3,
                  sm: 0
                },
                pt: 5,
                pb: 5
              }}
            >
              {showInvitation && (
                <>
                  <Box>
                    <InputLabel sx={{ color: '#CCCCCC', mb: 1, ml: 1 }}>Invite people through email</InputLabel>

                    <FormControl
                      sx={{
                        m: 1,
                        color: '#FFFFFF',
                        width: {
                          md: '50%',
                          sm: '50%'
                        },
                        '& .MuiInputBase-root': {
                          borderRadius: '10px'
                        }
                      }}
                    >
                      {emailChips.map((emailChip) => (
                        <Chip
                          key={emailChip}
                          label={'Sending emails to: ...' + emailChip}
                          onDelete={handleDeleteEmail(emailChip)}
                          sx={{
                            m: 0.5,
                            color: '#FFFFFF',
                            '& .MuiChip-deleteIcon': {
                              color: '#bdbdbd'
                            }
                          }}
                        />
                      ))}
                      <OutlinedInput
                        fullWidth
                        placeholder="user@gmail.com"
                        sx={{
                          '& .MuiInputBase-root': {
                            borderRadius: '10px'
                          },
                          color: '#FFFFFF',
                          mr: 0,
                          pr: 0
                        }}
                        type="email"
                        value={email}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={handleAddEmail}
                              sx={{
                                color: '#FFFFFF',
                                backgroundColor: '#181C1F',
                                pl: 7,
                                pr: 7,
                                mr: 0,
                                ml: 0
                              }}
                            >
                              Send
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ flexGrow: 1, mt: '45px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <InputLabel sx={{ color: '#CCCCCC', mb: 1, ml: 1 }}>or share your personal referral link</InputLabel>
                          <FormControl
                            sx={{
                              m: 1,
                              width: {
                                md: '100%',
                                sm: '50%'
                              },
                              '& .MuiInputBase-root': {
                                borderRadius: '10px'
                              }
                            }}
                          >
                            <OutlinedInput
                              placeholder="https://ratefy.ng/invite/1234567890  "
                              defaultValue={userData.referral_url}
                              readOnly
                              sx={{
                                '& .MuiInputBase-root': {
                                  borderRadius: '10px'
                                },
                                mr: 0,
                                pr: 0,
                                color: '#FFFFFF'
                              }}
                              type="email"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    sx={{
                                      color: '#FFFFFF',
                                      backgroundColor: '#181C1F',
                                      pl: 7,
                                      pr: 7,
                                      mr: 0,
                                      ml: 0
                                    }}
                                    onClick={() => handleCopyToClipboard(userData.referral_url)}
                                  >
                                    Copy
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <ToastContainer />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            ml: {
                              md: 10,
                              sm: 0
                            },
                            mt: {
                              sm: 5,
                              xs: 5
                            }
                          }}
                        >
                          <Box sx={{ ml: 2, color: '#CCCCCC' }}>Share</Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              mt: 1
                            }}
                          >
                            <Button onClick={fshare}>
                              <iconify-icon
                                icon="bxl:facebook-circle"
                                style={{
                                  color: '#00B172',
                                  fontSize: '40px'
                                }}
                              ></iconify-icon>
                            </Button>
                            <Button onClick={tshare}>
                              <iconify-icon
                                icon="formkit:twitter"
                                style={{
                                  color: '#00B172',
                                  fontSize: '40px'
                                }}
                              ></iconify-icon>
                            </Button>
                            <Button onClick={ishare}>
                              <iconify-icon
                                icon="mdi:linkedin"
                                style={{
                                  color: '#00B172',
                                  fontSize: '40px'
                                }}
                              ></iconify-icon>
                            </Button>
                            <Button onClick={wshare}>
                              <iconify-icon
                                icon="akar-icons:whatsapp-fill"
                                style={{
                                  color: '#00B172',
                                  fontSize: '40px'
                                }}
                              ></iconify-icon>
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              {showLearnMore && (
                <>
                  <Box
                    sx={{
                      width: '40%'
                    }}
                  >
                    <Box
                      sx={{
                        color: '#FFFFFF',
                        width: 'fit-content'
                      }}
                    >
                      <TableContainer
                        sx={{
                          backgroundColor: '#1C2225'
                        }}
                      >
                        <Table sx={{ minWidth: 650, color: '#FFFFFF' }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  p: 2,

                                  border: '2px solid #333333'
                                }}
                              >
                                <Box>List of invited emails</Box>
                                <Button onClick={sendReminder} variant="contained" sx={{ backgroundColor: '#00B172' }}>
                                  Send Reminder
                                </Button>
                              </Box>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {userData && userData.pontentialcustomer
                              ? userData.pontentialcustomer
                                  .filter((customer) => customer.status === 'unregistered')
                                  .map((customer, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        pl: 2,
                                        pt: 1
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'inline'
                                        }}
                                      >
                                        {customer.email}
                                      </Box>
                                      &nbsp;
                                      <Box
                                        sx={{
                                          display: 'inline'
                                        }}
                                      >
                                        <Button
                                          sx={{
                                            backgroundColor: '#B1AA00',
                                            p: 0,
                                            color: '#FFFFFF'
                                          }}
                                        >
                                          {customer.reminded}
                                        </Button>
                                      </Box>
                                    </Box>
                                  ))
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '40%',
                      mt: 5
                    }}
                  >
                    <Box
                      sx={{
                        color: '#FFFFFF',
                        width: 'fit-content'
                      }}
                    >
                      <TableContainer
                        sx={{
                          backgroundColor: '#1C2225'
                        }}
                      >
                        <Table sx={{ minWidth: 650, color: '#FFFFFF' }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <Box
                                sx={{
                                  p: 2,

                                  border: '2px solid #333333'
                                }}
                              >
                                <Box>Converted emails</Box>
                              </Box>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {userData && userData.pontentialcustomer
                              ? userData.pontentialcustomer
                                  .filter((customer) => customer.status === 'registered')
                                  .map((customer, index) => (
                                    <Box
                                      sx={{
                                        pl: 2
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'inline'
                                        }}
                                      >
                                        {customer.email}
                                      </Box>
                                    </Box>
                                  ))
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DashboardDefault;
