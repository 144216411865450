import { Box, Button } from '@mui/material';


const home = () => {
  localStorage.clear();
  window.location.href = 'https://ratefy.co/users/home';
};
const Search = () => (
  <Box sx={{ width: '100%', ml: { xs: 0, md: 20 } }}>
    <Button
      onClick={home}
      variant="outlined"
      sx={{
        color: '#EAEAEA',
        border: 'none',
        backgroundColor: '#5B5B5B',
        mr: 5
      }}
    >
      Switch to express
    </Button>
    <Button
      variant="outlined"
      sx={{
        color: '#EAEAEA',
        border: 'none',
        backgroundColor: '#5B5B5B'
      }}
    >
      Switch to P2P
    </Button>
  </Box>
);

export default Search;
