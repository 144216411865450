import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Grid, Typography, Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { relocate } from '../../helpers/relocate.js';
import { authorization } from '../../helpers/authorization.js';
import { destroyUser } from '../../helpers/destroyauth.js';
import { useDispatch } from 'react-redux';

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        ml: 0,
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx
      }}
      {...other}
    />
  );
}

const SamplePage = () => {
  const auth = useRef(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Scrutiny = async () => {
    console.log(user.users);
    console.log(user.users[0].uuid);
    console.log(user.users[0].subscribed);
    let authorize = authorization(user.users[0].uuid);
    if (authorize === false) {
      relocate();
    } else if (user.users[0].subscribed === true) {
      navigate('/v/partners');
    }
  };

  const relocateUser = () => {
    dispatch(destroyUser());
    window.location.replace('https://ratefy.co/users/home');
  };

  useEffect(() => {
    if (auth.current === true) {
      auth.current = false;
      Scrutiny();
    }
  });

  const handleNavigation = () => {
    const subscribeUser = async () => {
      try {
        axios.defaults.withCredentials = true;
        await axios.get('https://affiliatebased.ratefy.co/sanctum/csrf-cookie');
        const response = await axios.post(
          'https://affiliatebased.ratefy.co/api/subcribe-user',
          {
            uuid: user.users[0].uuid,
            firstname: user.users[0].firstname
          },
          {
            header: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            }
          }
        );
        // console.log(response.status);
        // setUserData(response.data?.user);

        if (response.status === 404) {
          console.log('step10');
          //stay on the page
          // navigate('/');
          // navigate('/partner');
        } else {
          navigate('/v/partners');
          console.log(response.data);
          //navigate to the dashboard
          //  navigate('/v/partners');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    subscribeUser(user.users[0].uuid, user.users[0].firstname);
  };

  // end of navigating routes

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const modalBody = (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        backgroundColor="none"
        background="none"
        PaperProps={{
          style: { backgroundColor: 'transparent' }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '24px', color: '#fff' }}>Partnership Terms and Conditions</DialogTitle>
        <Grid container spacing={1}>
          <Grid item md={10} sm={10} xs={12}>
            <DialogContent
              sx={{
                pl: 5,
                color: '#A6A6A6',
                backgroundColor: '#1C2225'
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'row',
                      mb: 3,
                      pt: 3
                    }}
                  >
                    
                    <Box
                      sx={{
                        color: '#FCD035',
                        mb: 1
                      }}
                    >
                      <Typography variant="p">Ratefy Affiliate Terms and Conditions</Typography>
                      <Typography variant="p">Affiliate Program Terms of Service</Typography>
                      <Typography variant="p"> Effective date: November 25, 2023</Typography>
                      <Typography variant="p">Thank you for opting in as a member of the Ratefy Affiliate Program.</Typography>
                      <Typography variant="p">
                        By continuing to use the Ratefy website (whether as an Affiliate or otherwise), you will be deemed to have agreed to
                        and accepted the Privacy Policy, including as it may from time to time hereafter be amended, supplemented, or
                        otherwise altered. The current version of our Privacy Policy can be accessed from the homepage of this site or by
                        clicking this link: https:/Ratefy.co/privacy. For the avoidance of doubt, please be aware that certain account
                        information may be shared with your Affiliates as part of your participation in the Affiliate Program, including but
                        not limited to profile details and account activity.
                      </Typography>
                      <Typography variant="p">
                        The following are the Terms and Conditions applicable to the Affiliate Program. Ratefy reserves the right (in its
                        sole and absolute discretion and without prior notice) to: (a) change any or all of these Terms and Conditions, at
                        any time and from time to time, including (but not limited to) by changing the commission payout percentages
                        described below, (b) suspend or cancel the Affiliate Program, and/or (c) suspend or permanently deactivate your
                        Affiliate and Ratefy account if you engage in certain conduct as described below.
                      </Typography>
                      <Typography variant="p">
                        As an Affiliate, you have the opportunity to introduce third parties to the Ratefy Platform and to earn commissions
                        based on their e-wallet trades made on our platform. Ratefy pays all Affiliate commissions solely in Naira..
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#333333',
                      pl: 2,
                      mb: 1,
                      fontSize: '14px',
                      color: '#fff'
                    }}
                  >
                    <Typography variant="h1">Commissions payable.</Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#333333',
                      pl: 2,
                      pt: 1,
                      pr: 2,
                      pb: 5,
                      mb: 3
                    }}
                  >
                    <Typography variant="p">
                      Unless otherwise agreed to by Ratefy, you will earn a commission from Ratefy each time your Referral completes an
                      e-wallet transaction using Ratefy.
                    </Typography>
                    <Typography variant="p">
                      As you know, Ratefy charges a 2% Escrow/service Fee in connection with each trade completed on its platform. The
                      Escrow Fee is subject to change by Ratefy at any time and from time to time.
                    </Typography>
                    <Typography variant="p">
                      When a Referral successfully completes a transaction using Ratefy, Ratefy will credit your Affiliate Wallet with a sum
                      (in NGN) equal to 25% of the then-effective Escrow/service Fee.
                    </Typography>
                    <Typography variant="p">
                      Your commission earned in connection with any such transaction will typically be credited to your Affiliate Wallet
                      promptly upon such transaction’s successful completion. Every time such a transaction is completed successfully,
                      Ratefy will send you a confirmation email. In this way, you can watch your Affiliate Wallet balance grow within your
                      Ratefy dashboard.
                    </Typography>
                  </Box>
                  <Box sx={{ backgroundColor: '#333333', pl: 2, mb: 2, fontSize: '14px', color: '#fff' }}>
                    <Typography variant="h1">Withdrawing Your Commissions.</Typography>
                    <Typography variant="p">
                      You will be able to send withdrawal request of the entire balance weekly on every Friday which will be approved
                      withing 24 hours by Ratefy. Once the total commission of your Affiliate earnings reaches #500,000 on each Referral,
                      you will no longer receive reward on the particular Referral.
                    </Typography>
                  </Box>
                  <Box sx={{ backgroundColor: '#333333', pl: 2, mb: 2, fontSize: '14px', color: '#fff' }}>
                    <Typography variant="h1">Prohibited Actions</Typography>
                    <Typography variant="p">
                      The following is a representative (but not exhaustive) list of the kinds of conduct and practices that could cause
                      Ratefy (as determined by Ratefy its sole and absolute judgment) to (a) cancel or reclaim payment of your then-earned
                      (whether withdrawn or not) Commissions, and/or (b) suspend or permanently deactivate your Affiliate and Ratefy
                      account(s).
                    </Typography>

                    <Typography variant="subtitle1">Using spam to attract parties to the Ratefy platform.</Typography>
                    <Typography variant="subtitle1">
                      Engaging in illegal activities of any kind, whether or not related to your use of Ratefy’s platform and services.
                    </Typography>
                    <Typography variant="subtitle1">
                      Creating additional accounts on the Ratefy platform that benefit from the Ratefy Affiliate Program in any form. You
                      are only allowed to have one account and are not allowed to sell, borrow, share or otherwise make available your
                      account or any detail necessary to access your account to people or entities other than yourself. Creating false
                      information for your account, providing fraudulent identification documents is strictly prohibited.
                    </Typography>
                    <Typography variant="subtitle1">
                      Any breach or violation by you or your representatives of any of your obligations under these Terms and Conditions.
                    </Typography>
                    <Typography variant="subtitle1">
                      The use of – in any form of advertising or other media or information of any kind which you originate or disseminate
                      or otherwise control – the words “Ratefy” or any other look-alike, sound-alike, or other words, marks, or designations
                      that Ratefy deems to be confusingly similar to its trademark RATEFY.
                    </Typography>
                    <Typography variant="subtitle1">
                      Disparaging, libeling, or defaming (a) RATEFY or any of its directors, officers, employees, contractors, agents, or
                      representatives, or (b) users of the Ratefy platform, including other members of the Affiliate Program.
                    </Typography>
                    <Typography variant="subtitle1">
                      Engaging in any activities that Ratefy believes (in its sole and absolute judgement) could subject it to legal,
                      regulatory, or prosecutorial scrutiny under the laws, rules, or regulations of any nation, multinational body, or
                      other jurisdiction or governmental authority including, but not limited to illegal gambling, fraud, money laundering,
                      or terrorist activities.
                    </Typography>
                    <Typography variant="subtitle1">
                      Engaging in illegal activities of any kind, whether or not related to your use of Ratefy’s platform and services.
                    </Typography>
                    <Typography variant="subtitle1">Provide false, inaccurate, or misleading information.</Typography>
                    <Typography variant="subtitle1">Provide false, inaccurate, or misleading identity and address verification.</Typography>
                    <Typography variant="subtitle1">
                      Encourage or induce any third party to engage in any of the activities prohibited under this Section.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#333333',
                      pl: 2,
                      pt: 1,
                      pr: 2,
                      pb: 5,
                      mb: 3
                    }}
                  >
                    <Typography variant="h1">Intellectual Property Rights</Typography>
                    <Typography variant="p">
                      Unless otherwise indicated by us, all intellectual property rights in the Website and in any content provided in
                      connection with our services, are the property of Ratefy or our licensors or suppliers and are protected by applicable
                      intellectual property laws. We do not give any implied license for the use of the contents of the Website. You may not
                      sell or modify the Website materials or reproduce, display, publicly perform, distribute or otherwise use the
                      materials in any way for any public or commercial purpose. Your use of the materials on any other website or on a
                      file-sharing or similar service for any purpose is prohibited.
                    </Typography>
                    <Typography variant="p">
                      You may not copy any material or content contained on the Website or accessible through the Website without our
                      written permission. Any rights not expressly granted herein to use the materials contained on the Website are reserved
                      by Ratefy in full.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#333333',
                      pl: 2,
                      pt: 1,
                      pr: 2,
                      pb: 5,
                      mb: 3
                    }}
                  >
                    <Typography variant="h1">Limitation of Liability/Disclaimer</Typography>
                    <Typography variant="p">
                      This site and the Affiliate Program are provided on an “as is” and “as available” basis for your information and use
                      without any representation or endorsement. To the maximum extent permitted by applicable law, we make no warranties of
                      any kind, whether express or implied, in relation to the site or the Affiliate Program, including but not limited to,
                      implied warranties of satisfactory quality, functionality, fitness for a particular purpose, non-infringement,
                      compatibility, security, accuracy, condition or completeness, or any implied warranty arising from course of dealing
                      or usage or trade.
                    </Typography>
                    <Typography variant="p">
                      If and to the maximum extent permitted by applicable law, we will not be liable for:
                    </Typography>
                    <Typography variant="subtitle1">
                      any economic losses (including without limitation loss of revenues, profits, contracts, business or anticipated
                      savings);
                    </Typography>
                    <Typography variant="subtitle1">any loss of goodwill or reputation</Typography>

                    <Typography variant="subtitle1">any special or indirect or consequential losses, howsoever arising.</Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#333333',
                      pl: 2,
                      mb: 1,
                      fontSize: '14px',
                      color: '#fff'
                    }}
                  >
                    <Typography variant="h1">Indemnity and Hold Harmless</Typography>
                    <Typography variant="p">
                      You agree to hold harmless Ratefy, Inc. (and each of our officers, directors, members, employees, agents and
                      affiliates) from any claim, demand, action, damage, loss, cost or expense, including without limitation reasonable
                      legal fees, arising out or relating to: your use of, or conduct in connection with, our Affiliate Program; or your
                      violation of these Terms and Conditions. In addition, you agree to be fully responsible for (and fully indemnify us
                      against) all claims, liability, damages, losses, costs and expenses, including legal fees, suffered by us and arising
                      out of or related to any breach of the Terms and Conditions by you or any other liabilities incurred by us arising out
                      of your use of the services and the Affiliate Program, or use by any other person accessing the services using your
                      user account, device or internet access account; or your violation of any law or rights of any third party.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#333333',
                      pl: 2,
                      pt: 1,
                      pr: 2,
                      pb: 5,
                      mb: 3
                    }}
                  >
                    <Typography variant="h1">General</Typography>
                    <Typography variant="p">
                      These Terms and Conditions and your use of the Website and Affiliate Program shall be governed by and construed in
                      accordance with United States Law. Any dispute arising in connection with these Terms and Conditions or your use of
                      the Website or Affiliate Program will be resolved exclusively in the courts of Nigeria. Nothing in these Terms and
                      Conditions shall be deemed to affect your statutory rights under Nigeria law. If any part of these Terms and
                      Conditions is held by any court of Nigeria to be invalid or unenforceable in whole or in part, the validity or
                      enforceability of the other sections of these terms and conditions shall not be affected. Any headings contained in
                      these Terms and Conditions are for informational purposes only and are not enforceable provisions of these Terms and
                      Conditions.
                    </Typography>
                  </Box>

                  <DialogActions
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#1C2225'
                    }}
                  >
                    <Button
                      variant="contained"
                      autoFocus
                      // onClick={handleClose}
                      onClick={handleNavigation}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#00B172',
                        pl: 10,
                        pr: 10
                      }}
                    >
                      Agree and continue
                    </Button>
                  </DialogActions>
                </Grid>
                <Grid item></Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={2} sm={2}>
            <Button
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                backgroundColor: '#00B172',
                color: '#000000'
              }}
            >
              <iconify-icon
                icon="icon-park-solid:big-x"
                style={{
                  margin: 0,
                  padding: 0
                }}
              ></iconify-icon>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );

  useEffect(() => {
    if (auth.current === true) {
      auth.current = false;
      Scrutiny();
    }
  });
  return (
    <>
      <Box>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: '#000000',
                pl: {
                  md: 9,
                  xs: 0
                },
                pt: 6,
                pb: 7,
                height: '100%'
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    md: '37px',
                    sm: '37px',
                    xs: '26px'
                  },
                  color: '#FFFFFF',
                  display: 'flex',
                  justifyContent: { md: 'left', xs: 'center' },
                  fontWeight: 'bold'
                }}
              >
                Refer one user,
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: '37px',
                    xs: '26px'
                  },
                  color: '#FFFFFF',
                  mt: 0,
                  pt: 0,
                  display: 'flex',
                  justifyContent: { md: 'left', xs: 'center' }
                }}
              >
                Earn up to &nbsp;
                <span
                  style={{
                    background: `linear-gradient(to right, #3AFF4E, #3AFF4E)`,
                    WebkitBackgroundClip: 'text',
                    color: 'transparent'
                  }}
                >
                  {''} &#8358;500,000
                </span>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { md: 'left', xs: 'center' }
                }}
              >
                <Button
                  onClick={handleClickOpen()}
                  sx={{
                    backgroundColor: '#00B172',
                    color: '#FFFFFF',
                    borderRadius: '10px',
                    pl: 4,
                    pr: 4,
                    fontSize: '18px'
                  }}
                >
                  Get Started
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                  sx={{
                    backgroundColor: 'none'
                  }}
                >
                  {modalBody}
                </Dialog>
              </Box>

              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#BBBBBB',
                  mt: 1,
                  display: 'flex',
                  justifyContent: { md: 'left', xs: 'center' }
                }}
              >
                Terms and conditions applied
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              sx={{
                display: 'flex',
                width: '100%',
                m: 0
              }}
            >
              <Grid item xs={6} md={6}>
                <img
                  width="100%"
                  height="100%"
                  src="https://res.cloudinary.com/pro-solve/image/upload/v1698314890/european-guy-is-showing-smth-tablet-he-is-laughing-together-with-afroamerican-guy_1_1_xtwvqo.png"
                  alt="man"
                ></img>
              </Grid>

              <Grid
                item
                width="100%"
                xs={6}
                md={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Grid item>
                  <img
                    width="100%"
                    height="100%"
                    alt="man"
                    src="https://res.cloudinary.com/pro-solve/image/upload/v1698315020/neeeeew_sjushz.png"
                  ></img>
                </Grid>
                <Grid>
                  <img
                    width="100%"
                    height="100%"
                    alt="man"
                    src="https://res.cloudinary.com/pro-solve/image/upload/v1698315071/newwwwww_ekdjuy.png"
                  ></img>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            ml: {
              md: 9,
              xs: 3,
              sm: 3
            },
            mr: {
              md: 9,
              xs: 3,
              sm: 3
            },
            pt: 3
          }}
        >
          <Box
            sx={{
              color: '#00B172',
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'left'
              }
            }}
          >
            Earn through Revenue share
          </Box>
          <Box
            sx={{
              color: '#FFFFFF',
              fontSize: {
                md: '32px',
                xs: '24px'
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'left'
              }
            }}
          >
            How Ratefy Partnership works?
          </Box>

          <Grid container>
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: {
                  md: 'space-between',
                  xs: 'center'
                }
              }}
            >
              <Grid item xs={12} sm={4} md={4}>
                <Box sx={{ mt: 5 }}>
                  <Box
                    sx={{
                      pr: {
                        md: 15,
                        xs: 0
                      },
                      textAlign: {
                        xs: 'center',
                        sm: 'center',
                        md: 'left'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        mb: 1,
                        flexDirection: 'row',
                        justifyContent: {
                          md: 'space-between',
                          xs: 'center'
                        }
                      }}
                    >
                      <Box
                        component="img"
                        width="15%"
                        alt="call"
                        src="https://res.cloudinary.com/pro-solve/image/upload/v1697072135/Group_r6qlwh.png"
                        sx={{
                          display: 'flex',
                          justifyContent: {
                            md: 'block',
                            xs: 'center'
                          }
                        }}
                      ></Box>
                      {/* <Box
                        component="img"
                        width="auto%"
                        alt="call"
                        src="https://res.cloudinary.com/pro-solve/image/upload/v1697104650/Vector_yjyqwv.png"
                        sx={{
                          display: { md: 'block', xs: 'none' }
                        }}
                      ></Box> */}
                    </Box>
                    <Box
                      sx={{
                        color: '#FFFFFF'
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 'bold'
                        }}
                      >
                        You Invite
                      </Box>
                      <Box
                        sx={{
                          color: '#BBBBBB'
                        }}
                      >
                        Invite people to Ratefy through email, referral code or link. Untilize Word-of-mouth and Social media
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <Box sx={{ mt: 5 }}>
                  <Box
                    sx={{
                      pr: {
                        md: 15,
                        xs: 0
                      },
                      textAlign: {
                        xs: 'center',
                        sm: 'center',
                        md: 'left'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        mb: 1,
                        flexDirection: 'row',
                        justifyContent: {
                          md: 'space-between',
                          xs: 'center'
                        }
                      }}
                    >
                      <Box
                        component="img"
                        width="20%"
                        alt="call"
                        src="https://res.cloudinary.com/pro-solve/image/upload/v1697101565/Group_20435_djykpv.png"
                        sx={{
                          display: 'flex',
                          justifyContent: {
                            md: 'block',
                            xs: 'center'
                          }
                        }}
                      ></Box>
                      {/* <Box
                        component="img"
                        width="auto%"
                        alt="call"
                        src="https://res.cloudinary.com/pro-solve/image/upload/v1697104650/Vector_yjyqwv.png"
                        sx={{
                          display: { md: 'block', xs: 'none' }
                        }}
                      ></Box> */}
                    </Box>
                    <Box
                      sx={{
                        color: '#FFFFFF'
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 'bold'
                        }}
                      >
                        They exchange
                      </Box>
                      <Box
                        sx={{
                          color: '#BBBBBB'
                        }}
                      >
                        Your Referrals sign up using your link/code, they are rewarded with an express support contact, they exchange on
                        Ratefy.
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <Box sx={{ mt: 5 }}>
                  <Box
                    sx={{
                      pr: {
                        md: 15,
                        xs: 0
                      },
                      textAlign: {
                        xs: 'center',
                        sm: 'center',
                        md: 'left'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        mb: 1,
                        flexDirection: 'row',
                        justifyContent: {
                          md: 'space-between',
                          xs: 'center'
                        }
                      }}
                    >
                      <Box
                        component="img"
                        width="15%"
                        alt="call"
                        src="https://res.cloudinary.com/pro-solve/image/upload/v1697101750/Group_1_dkpj8a.png"
                        sx={{
                          display: 'flex',
                          justifyContent: {
                            md: 'block',
                            xs: 'center'
                          }
                        }}
                      ></Box>
                      {/* <Box
                        component="img"
                        width="auto%"
                        alt="call"
                        src="https://res.cloudinary.com/pro-solve/image/upload/v1697104650/Vector_yjyqwv.png"
                        sx={{
                          display: { md: 'block', xs: 'none' }
                        }}
                      ></Box> */}
                    </Box>
                    <Box
                      sx={{
                        color: '#FFFFFF'
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 'bold'
                        }}
                      >
                        You get your Revenue Share
                      </Box>
                      <Box
                        sx={{
                          color: '#BBBBBB'
                        }}
                      >
                        Ratefy charges 2% fee on every exchange. You get 25% of every revenue generate on your referrer on till a total of
                        &#8358;500,000 is earned on each referral
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Button
            onClick={handleClickOpen()}
            variant="outlined"
            sx={{
              border: '1px solid #00B172',
              color: '#00B172',
              mt: 5,
              pl: 5,
              pr: 5,
              display: { md: 'block', xs: 'none' },
              '&:hover': {
                backgroundColor: '#00B172',
                color: 'white',
                border: 'none'
              }
            }}
          >
            Join Now
          </Button>

          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mt: 10,
              color: '#FFFFFF'
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%'
              }}
            >
              <Box
                sx={{
                  fontSize: '32px',
                  fontWeight: '700',
                  pb: 3,
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'left'
                  }
                }}
              >
                Ratefy Partner Benefits
              </Box>
              <Box
                sx={{
                  pb: 3,
                  color: '#EAEAEA'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '26px'
                  }}
                >
                  Maximum Earnings
                </Typography>
                <Typography
                  sx={{
                    color: '#BBBBBB'
                  }}
                >
                  Get paid every time your referral exchange till you earn ₦500,000 on each
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 3,
                  color: '#EAEAEA'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '26px'
                  }}
                >
                  Professional Support
                </Typography>
                <Typography
                  sx={{
                    color: '#BBBBBB'
                  }}
                >
                  Gain access to professional support, tutorials, and a dedicated Affiliate manager
                </Typography>
              </Box>
              <Box
                sx={{
                  color: '#EAEAEA'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '26px'
                  }}
                >
                  Intuitive Dashboards
                </Typography>
                <Typography
                  sx={{
                    color: '#BBBBBB'
                  }}
                >
                  Easy-to-use dashboards to launch, manage and monitor campaigns
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              maxWidth="100%"
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 1,
                maxWidth: '100%',

                width: '100%'
              }}
            >
              {/* <iframe width="100%" height="100%" src="https://www.youtube.com/embed/4zKcDi4oYGQ?autoplay=1&mute=0" frameborder="0"></iframe> */}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: '#00B172',
            mt: 10
          }}
        >
          <Box
            sx={{
              pt: '60.01px',
              pb: '60.01px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: {
                  md: '30px',
                  sm: '30px',
                  xs: '24px'
                },
                color: '#FFFFFF'
              }}
            >
              Interested in Becoming a
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: {
                  md: '30px',
                  sm: '24px',
                  xs: '24px'
                },
                color: '#FFFFFF'
              }}
            >
              Ratefy Partner?
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                onClick={handleClickOpen()}
                variant="contained"
                sx={{
                  backgroundColor: '#FFFFFF',
                  color: '#444444',
                  border: '2px solid none',
                  width: 'fit-content',
                  pl: 3,
                  pr: 3,
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white'
                  }
                }}
              >
                Join Now
              </Button>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '8px',
                pt: 1
              }}
            >
              Terms and conditions applied
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SamplePage;
