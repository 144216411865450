import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import MainCard from 'components/MainCard';
import ProfileTab from './ProfileTab';
import SettingTab from './SettingTab';
import avatar1 from 'assets/images/avatar-1.png';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}

const Profile = () => {
  const theme = useTheme();

  const handleLogout = async () => {};

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iconBackColorOpen = 'grey.300';

  // const storedData = localStorage.getItem('userData');
  // const { uuid, firstname } = JSON.parse(storedData);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar
            alt="profile user"
            src="https://res.cloudinary.com/pro-solve/image/upload/v1698762658/unnamed_xfpex9.png"
            // src={avatar1}
            sx={{
              width: {
                md: 32,
                sm: 32,
                xs: 20
              },
              // height: 32
              height: {
                md: 32,
                sm: 32,
                xs: 20
              }
            }}
          />
          {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: {
                  md: '15px',
                  sm: '15px',
                  xs: '10px'
                }
              }}
            >
              Affiliate Member
            </Typography>
            {/* <Typography
              sx={{
                color: '#A83838',
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: {
                  md: '12px',
                  sm: '12px',
                  xs: '10px'
                }
              }}
            >
              Unverified
            </Typography> */}
          </Box>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      ></Popper>
    </Box>
  );
};

export default Profile;
