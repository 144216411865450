import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, ClickAwayListener, IconButton, Paper, Popper, Toolbar, Button } from '@mui/material';
import Search from './Search';
import Profile from './Profile';
import { MoreOutlined } from '@ant-design/icons';
const MobileSection = () => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const home = () => {
    localStorage.clear();
    window.location.href = 'https://ratefy.co/users/home';
  };

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
       
        <Button
          onClick={home}
          size="small"
          variant="outlined"
          sx={{
            color: '#EAEAEA',
            border: 'none',
            backgroundColor: '#5B5B5B',
            mr: 1,
            fontSize: '8px'
          }}
        >
          Switch to express
        </Button>
        <Button
         size="small"
          variant="outlined"
          sx={{
            color: '#EAEAEA',
            border: 'none',
            backgroundColor: '#5B5B5B',
            fontSize: '8px'
          }}
        >
          Switch to P2P
        </Button>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          width: '100%'
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
            <ClickAwayListener onClickAway={handleClose}>
              <AppBar
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#0C0E0F'
                }}
              >
                <Toolbar>
                  <Search />
                  <Profile />
                </Toolbar>
              </AppBar>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

export default MobileSection;
